import { MedicalSpeciality } from 'pages/RPM/PatientProfile/Comments/types';
import { APICore } from './apiCore';
import { activeConfig } from 'config/clientConfig';

const api = new APICore();

const getUserDoctorSpecialties = async (params: { doctorUuid: string }) => {
    const baseUrl = `/${activeConfig.api.DOMAIN}/doctorSpecialties/get`;
    
    return api.create(`${baseUrl}`, params);
};

export { 
    getUserDoctorSpecialties 
};